import React, { Component } from "react";
import Header from "./Header";
import MainBannerAltText from "./MainBannerAltText";
import FeatureBlockAlt from "./FeatureBlockAlt";
import CTABlock from "./CTABlock";
import IonMock from "./IonMock.png";
import PlainMock from "./PlainMock.png";
import FocusMock from "./FocusMock.png";
import LavMock from "./LavMock.png";
import Footer from "./Footer";
import { Route, NavLink, HashRouter } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import Terms from "./Terms";
import Privacy from "./Privacy";

class Main extends Component {
  render() {
    return (
      <HashRouter>
        <div className="container">
          <Header />
        </div>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
      </HashRouter>
    );
  }
}

export default Main;
