import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import IonMock from "./IonMock.png";

class InlineMailchimpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { emailvalue: "" };
  }
  render() {
    return (
      <div className="input-group mb-3 " id="email-capture">
        <form
          action="https://relawriter.us17.list-manage.com/subscribe/post"
          method="POST"
          noValidate
          className="main-banner-cap"
        >
          <input type="hidden" name="u" value="732c2266adbf12220729d78f0" />
          <input type="hidden" name="id" value="35c6a558d6" />
          <label htmlFor="MERGE0">
            <div className="input-group mb-3" id="email-capture">
              <input
                type="email"
                name="EMAIL"
                id="MERGE0"
                value={this.state.emailValue}
                onChange={(e) => {
                  this.setState({ emailValue: e.target.value });
                }}
                autoCapitalize="off"
                autoCorrect="off"
                className="form-control"
                placeholder="Email Address"
                aria-label="Email Address"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button btn btn-primary"
                />
              </div>
            </div>
          </label>
        </form>
      </div>
    );
  }
}

export default InlineMailchimpForm;
