import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import InlineMailchimpForm from "./InlineMailchimpForm";

class MainBannerAltText extends React.Component {
  constructor(props) {
    super(props);
    this.state = { emailvalue: "" };
  }
  render() {
    return (
      <div id="main-banner-alt">
        <Row>
          <Col lg={3} md={2}></Col>
          <Col lg={6} md={8}>
            <center>
              <div className="vertically-center-banner-alt-text">
                <h1 id="banner-title" className="display-font">
                  A Modern, Minimalist Text Editor <br /> For Your Next Novel{" "}
                </h1>
                <p style={{ fontSize: "16px" }}>
                  Rela Writer is currently being built by writers, for writers.
                  Want early access, future updates, and the chance to provide
                  your input during development? Just enter your email below and
                  we’ll reach out.
                </p>
                <InlineMailchimpForm />

                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                  aria-label="Please leave the following three fields empty"
                >
                  <label htmlFor="b_name">Name: </label>
                  <input
                    type="text"
                    name="b_name"
                    tabIndex="-1"
                    value=""
                    placeholder="Freddie"
                    id="b_name"
                  />

                  <label htmlFor="b_email">Email: </label>
                  <input
                    type="email"
                    name="b_email"
                    tabIndex="-1"
                    value=""
                    placeholder="youremail@gmail.com"
                    id="b_email"
                  />

                  <label htmlFor="b_comment">Comment: </label>
                  <textarea
                    name="b_comment"
                    tabIndex="-1"
                    placeholder="Please comment"
                    id="b_comment"
                  ></textarea>
                </div>
              </div>
            </center>
          </Col>
          <Col lg={3} md={2}></Col>
        </Row>
      </div>
    );
  }
}

export default MainBannerAltText;
