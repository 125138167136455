import React from "react";
import { Route, NavLink, HashRouter } from "react-router-dom";
import { Row, Col, Grid } from "react-bootstrap";
import InlineMailchimpForm from "./InlineMailchimpForm";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { emailvalue: "" };
  }
  render() {
    return (
      <div id="header">
        <nav className="navbar navbar-expand-md navbar-light bg-light-rela">
          <a
            className="navbar-brand display-font primary-text-color"
            href="/"
            style={{ fontSize: "34px", color: "#424035" }}
          >
            Rela Writer
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-nav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-nav">
            <ul className="navbar-nav mr-auto"></ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link">
                  <NavLink to="/">Home</NavLink>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <NavLink to="/contact">Contact Us</NavLink>
                </a>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#emailCapModal"
                >
                  Get Rela Writer
                </button>
              </li>
            </ul>
          </div>
        </nav>
        <div
          class="modal fade"
          id="emailCapModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <Row height={10}>
                  <Col
                    md={{ offset: 0, span: 12 }}
                    xl={{ offset: 0, span: 12 }}
                  >
                    <center>
                      <h1 className="display-font">Want Rela Writer?</h1>
                      <p style={{ fontSize: "16px" }}>
                        Rela Writer is currently a work in progress, but we’ll
                        be providing early access in the very near future. If
                        you’d like updates on the project, and the chance at
                        early access, leave your email below and we’ll reach out
                        to you soon.
                      </p>
                      <div className="input-group mb-3" id="email-capture-pop">
                        <form
                          action="https://relawriter.us17.list-manage.com/subscribe/post"
                          method="POST"
                          noValidate
                        >
                          <input
                            type="hidden"
                            name="u"
                            value="732c2266adbf12220729d78f0"
                          />
                          <input type="hidden" name="id" value="35c6a558d6" />
                          <label htmlFor="MERGE0">
                            <div
                              className="input-group mb-3"
                              id="email-capture"
                            >
                              <input
                                type="email"
                                name="EMAIL"
                                id="MERGE00"
                                value={this.state.emailValue}
                                onChange={(e) => {
                                  this.setState({ emailValue: e.target.value });
                                }}
                                autoCapitalize="off"
                                autoCorrect="off"
                                className="form-control"
                                placeholder="Email Address"
                                aria-label="Email Address"
                                aria-describedby="basic-addon2"
                              />
                              <div className="input-group-append">
                                <input
                                  type="submit"
                                  value="Subscribe"
                                  name="subscribe"
                                  id="mc-embedded-subscribe"
                                  className="button btn btn-primary"
                                />
                              </div>
                            </div>
                          </label>
                        </form>
                      </div>
                    </center>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
