import React from "react";
import { Row, Col } from "react-bootstrap";
import { Route, NavLink, HashRouter } from "react-router-dom";

function Footer() {
  return (
    <div id="footer">
      <Row>
        <Col xl={{ span: 12, offset: 0 }} id="footer-brand">
          <span>
            Rela Writer© 2020
            <br />
          </span>
        </Col>
        <Col
          sm={{ offset: 4 }}
          xs={{ offset: 1 }}
          md={{ offset: 3 }}
          xl={{ span: 6, offset: 0 }}
          id="footer-items"
        >
          {" "}
          <span>
            <NavLink to="/terms">Terms</NavLink>
          </span>
          <span>
            <NavLink to="/privacy">Privacy</NavLink>
          </span>
          <span>
            <NavLink to="/contact">Contact Us</NavLink>
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
