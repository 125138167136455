import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <div className="container">
        <h1 className="display-font">Got Questions?</h1>
        <p>Shoot us an email at contact@relawriter.com</p>
      </div>
    );
  }
}

export default Contact;
