import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import InlineMailchimpForm from "./InlineMailchimpForm";

function CTABlock() {
  return (
    <div id="cta-block">
      <Row height={10}>
        <Col md={{ offset: 0, span: 12 }} xl={{ offset: 3, span: 6 }}>
          <center>
            <h1 className="display-font">Want Rela Writer?</h1>
            <p style={{ fontSize: "16px" }}>
              Rela Writer is currently a work in progress, but we’ll be
              providing early access in the very near future. If you’d like
              updates on the project, and the chance at early access, leave your
              email below and we’ll reach out to you soon.
            </p>
            <InlineMailchimpForm />
          </center>
        </Col>
      </Row>
    </div>
  );
}

export default CTABlock;
