import React, { Component } from "react";
import Header from "./Header";
import MainBannerAltText from "./MainBannerAltText";
import FeatureBlockAlt from "./FeatureBlockAlt";
import CTABlock from "./CTABlock";
import IonMock from "./IonMock.png";
import PlainMock from "./PlainMock.png";
import FocusMock from "./FocusMock.png";
import LavMock from "./LavMock.png";
import Footer from "./Footer";
import { Route, NavLink, HashRouter } from "react-router-dom";

class Home extends Component {
  block_one = {
    title: "Your Editor, Your Look",
    body:
      "You’re the one spending the next hundred-thousand words or so staring at your text editor, so shouldn’t you be the one who decides how it looks? With Rela Writer, every color of every element is customizable to your desire. Don’t want to take the time to find your dream palette yourself? One of our dozens of pre-made themes are sure to match your perfect aesthetic.",
    image: IonMock,
  };

  block_two = {
    title: "Easy To Tweak, Easier To Focus",
    body:
      "Rela Writer has many features to improve the writing process, but once the writing begins, all distractions go away. Focus mode is enabled automatically once you begin typing in your manuscript by default. There is no friction needed between inspiration and implementation, just get to work, and Rela Writer will remove all distractions to keep you in the flow.",
    image: FocusMock,
  };

  block_three = {
    title: "Effortlessly Manage Your Project",
    body:
      "Juggling character, setting, plot, and research is made simple with Rela Writer. Easily create notes of any type using our project manager and custom templates, then access these notes with our unique interlinking feature. Want to jot down a few quick notes on a character? Simply double click their name within your manuscript and you’ll be brought to their page immediately.",
    image: LavMock,
  };

  block_four = {
    title: "Navigate Your Work With Ease",
    body:
      "Keep your chapters, notes, and outlines open and easily accessible with Rela Writer’s tab management system. Organize these files as you please, and Rela Writer will include only necessary files in your manuscript, along with formatting them as needed. With Rela Writer, how you choose to organize your project while writing isn’t hindered by rigid manuscript requirements. Simply get your ideas onto paper, and we’ll take it from there.",
    image: PlainMock,
  };
  render() {
    return (
      <>
        <div className="container" style={{ paddingBottom: "55px" }}>
          <MainBannerAltText />
        </div>
        <div className="container-fluid">
          <FeatureBlockAlt
            title={this.block_one.title}
            body={this.block_one.body}
            image={this.block_one.image}
            invert={true}
          />
          <FeatureBlockAlt
            title={this.block_two.title}
            body={this.block_two.body}
            image={this.block_two.image}
            invert={false}
          />
          <FeatureBlockAlt
            title={this.block_three.title}
            body={this.block_three.body}
            image={this.block_three.image}
            invert={true}
          />
          <FeatureBlockAlt
            title={this.block_four.title}
            body={this.block_four.body}
            image={this.block_four.image}
            invert={false}
          />
        </div>
        <div className="container">
          <CTABlock />
          <Footer />
        </div>
      </>
    );
  }
}

export default Home;
