import React from "react";
import { Row, Col } from "react-bootstrap";

function FeatureBlockAlt(props) {
  if (!props.invert) {
    return (
      <div id="main-banner-alt-image" style={{ paddingBottom: "80px" }}>
        <Row className="h-100">
          <Col
            xl={{ span: 3, offset: 2 }}
            style={{ marginTop: "0px" }}
            md={{ order: "last", size: 12 }}
            className="my-auto"
          >
            <h3>{props.title}</h3>
            <p>{props.body}</p>
          </Col>
          <Col
            xl={{ span: 5, order: "last" }}
            md={12}
            xs={{ order: "first" }}
            className="feature-img"
          >
            <img src={props.image} style={{ width: "100%" }} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div id="main-banner-alt-image" style={{ paddingBottom: "80px" }}>
        <Row className="h-100">
          <Col xl={5} xl={{ offset: 2 }} className="feature-img">
            <img src={props.image} style={{ width: "100%" }} />
          </Col>
          <Col xl={3} style={{ marginTop: "85px" }} className="my-auto">
            <h3>{props.title}</h3>
            <p>{props.body}</p>
          </Col>
          <Col xl={2}></Col>
        </Row>
      </div>
    );
  }
}

export default FeatureBlockAlt;
