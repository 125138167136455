import React from "react";
import { Row, Col } from "react-bootstrap";

function Terms() {
  return (
    <div id="terms" className="container">
      <p>
        {" "}
        These terms and conditions outline the rules and regulations for the use
        of Rela Writer's Website. By accessing this website we assume you accept
        these terms and conditions in full. Do not continue to use Rela Writer's
        website if you do not accept all of the terms and conditions stated on
        this page. The following terminology applies to these Terms and
        Conditions, Privacy Statement and Disclaimer Notice and any or all
        Agreements: “User”, “You” and “Your” refers to you, the person accessing
        this website and accepting the Company’s terms and conditions. “Rela
        Writer”, “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our
        Company. “Party”, “Parties”, or “Us”, refers to both the Users and
        ourselves, or either the User or ourselves. Any use of the above
        terminology or other words in the singular, plural, capitalisation
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same. We employ the use of cookies. By using Rela Writer's
        website you consent to the use of cookies in accordance with Rela
        Writer’s privacy policy. Most of the modern day interactive websites use
        cookies to enable us to retrieve user details for each visit. Cookies
        are used in some areas of our site to enable the functionality of this
        area and ease of use for those people visiting. Some of our affiliate /
        advertising partners may also use cookies. The following organizations
        may link to our Web site without prior written approval: Government
        agencies; Search engines; News organizations; Online directory
        distributors when they list us in the directory may link to our Web site
        in the same manner as they hyperlink to the Web sites of other listed
        businesses; and Systemwide Accredited Businesses except soliciting
        non-profit organizations, charity shopping malls, and charity
        fundraising groups which may not hyperlink to our Website. These
        organizations may link to our home page, to publications or to other Web
        site information so long as the link: (a) is not in any way misleading;
        (b) does not falsely imply sponsorship, endorsement or approval of the
        linking party and its products or services; and (c) fits within the
        context of the linking party's site. We may consider and approve in our
        sole discretion other link requests from the following types of
        organizations: Commonly-known consumer and/or business information
        sources such as Chambers of Commerce, American Automobile Association,
        AARP and Consumers Union; Dot.com community sites; Associations or other
        groups representing charities, including charity giving sites, online
        directory distributors; Internet portals; Accounting, law and consulting
        firms whose primary Users are businesses; Educational institutions and
        trade associations. We will approve link requests from these
        organizations if we determine that: (a) the link would not reflect
        unfavorably on us or our accredited businesses (for example, trade
        associations or other organizations representing inherently suspect
        types of business, such as work-at-home opportunities, shall not be
        allowed to link); (b)the organization does not have an unsatisfactory
        record with us; (c) the benefit to us from the visibility associated
        with the hyperlink outweighs the absence of ; and (d) where the link is
        in the context of general resource information or is otherwise
        consistent with editorial content in a newsletter or similar product
        furthering the mission of the organization. These organizations may link
        to our home page, to publications or to other Web site information so
        long as the link: (a) is not in any way misleading; (b) does not falsely
        imply sponsorship, endorsement or approval of the linking party and it
        products or services; and (c) fits within the context of the linking
        party's site. If you are among the organizations listed in paragraph 2
        above and are interested in linking to our website, you must notify us
        by sending an email to contact@relawriter.com. Please include your name,
        your organization name, contact information (such as a phone number
        and/or email address) as well as the URL of your site, a list of any
        URLs from which you intend to link to our Web site, and a list of the
        URL(s) on our site to which you would like to link. Allow 2-3 weeks for
        a response. Approved organizations may hyperlink to our Web site as
        follows: By use of our corporate name; or By use of the uniform resource
        locator (Web address) being linked to; or By use of any other
        description of our Web site or material being linked to that makes sense
        within the context and format of content on the linking party's site. No
        use of Rela Writer’s logo or other artwork will be allowed for linking
        absent a trademark license agreement. Whilst we endeavour to ensure that
        the information on this website is correct, we do not warrant its
        completeness or accuracy; nor do we commit to ensuring that the website
        remains available or that the material on the website is kept up to
        date. To the extent that the website and the information and services on
        the website are provided free of charge, we will not be liable for any
        loss or damage of any nature.
      </p>
    </div>
  );
}

export default Terms;
